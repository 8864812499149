import styled from 'styled-components';

export const ContainerButtonModal = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.875rem;
  align-items: flex-end;
  gap: 0.6875rem;
  width: 100%;

  button {
    border: none;
  }

  .button-imprimir {
    width: 6.25rem;
    color: #9802fe;
    background-color: transparent;
    text-decoration: underline;
    font-size: medium;

    svg {
      margin-right: 0.3125rem;
    }
  }

  .button-ok {
    width: 100%;
    padding: 0.4375rem;
    border-radius: 0.3125rem;
    background-color: #60d49f;
    color: white;
    font-weight: bold;
    font-size: medium;

    &:hover {
      opacity: 93%;
    }
  }
`;
