import styled from 'styled-components';

export const ContainerFinalizadoras = styled.div`
  display: flex;
  flex-direction: column;

  .header-finalizadoras {
    display: flex;
    flex-direction: row;
    height: 1.75rem;

    p {
      color: #9802fe;
      font-weight: 700;
      margin-top: 0.175rem;
      margin-left: 0.3125rem;
    }
  }

  .body-finalizadoras {
    .finalizadoras {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding-left: 1.875rem;

      &:before {
        content: '';
        position: absolute;
        right: 0;
        top: 19px;
        width: calc(100% - 1.875rem);
        height: 0.5px;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
`;
