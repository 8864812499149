import React, { useCallback, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useFocusTabFinalizacao } from '~/pages/PdvOnline/hooks/useFocusTabFinalizacao';
import { useFocusTabProduto } from '~/pages/PdvOnline/hooks/useFocusTabProduto';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import { ModalType } from '~/pages/PdvOnline/types/context';
import { codTipoMov } from '~/pages/PdvOnline/utils/codTipoMov';
import { Modal } from '..';
import { Button, ButtonContainer } from './styles';
import { functionsMap } from './utils/functions';
import { getLeituraCaixa } from '~/pages/PdvOnline/services/getLeituraCaixa';
import { calcularTotaisLeitura } from '~/pages/PdvOnline/utils/calcularTotaisLeitura';
import ImprimirLeituraCaixa from '~/utils/classes/ImpressaoRecibo/PdvOnline/ImprimirLeituraCaixa';
import { gerarCupomCaixa } from '../ModalBuscarVendas/utils/gerarCupomCaixa';

const MySwal = withReactContent(Swal);

export const ModalFunctions: React.FC = () => {
  const {
    isOpenModalFunction,
    handleChangeOpenModalFunction,
    initialStatePdv,
    toggleStatusPdv,
    handleInitialStatePdv,
    tabActive,
    onOpenModal,
    handleResetPdv,
    onCloseModal,
  } = usePdvOnline();

  const { handleInputFocus } = useFocusTabProduto();
  const { handleInputFocus: focusTabFinalizacao } = useFocusTabFinalizacao();

  useEffect(() => {
    const handleCloseModal = (event: KeyboardEvent) => {
      if (isOpenModalFunction && event.key === 'Escape') {
        handleChangeOpenModalFunction(false);
        if (tabActive === 'PRODUTOS') handleInputFocus('leitor');
        else if (tabActive === 'FINALIZACAO') focusTabFinalizacao('num_cpf');
      }
    };

    document.addEventListener('keydown', handleCloseModal);
    return () => document.removeEventListener('keydown', handleCloseModal);
  }, [
    focusTabFinalizacao,
    handleChangeOpenModalFunction,
    handleInputFocus,
    isOpenModalFunction,
    onCloseModal,
    tabActive,
  ]);

  useEffect(() => {
    const modal: HTMLDivElement | null = document.querySelector(
      '[id="modal-funcao"]',
    );

    if (modal && isOpenModalFunction) modal.focus();
  }, [isOpenModalFunction]);

  const handleClosePdv = useCallback(async () => {
    const confirmClosePdv = async (): Promise<boolean> => {
      const result = await MySwal.fire({
        title: 'Confirmação',
        text: 'Deseja realmente realizar o fechamento?',
        showCancelButton: true,
        confirmButtonColor: '#019C0B',
        cancelButtonColor: '#999999',
        confirmButtonText: 'CONFIRMAR',
        cancelButtonText: 'CANCELAR',
      });
      return result.isConfirmed;
    };

    if (!initialStatePdv) return;

    const shouldClosePdv = await confirmClosePdv();
    if (!shouldClosePdv) return;

    const { num_pdv, cod_loja, cod_operador, val_saldo } = initialStatePdv;

    const lancamento = await aberturaPdv.insereMovimento(num_pdv, cod_loja, {
      cod_operador,
      cod_tipo_mov: codTipoMov.FECHAMENTO,
      val_mov: val_saldo,
    });

    if (!lancamento) return;

    handleChangeOpenModalFunction(false);
    onOpenModal('operadorCaixa');
    toggleStatusPdv(false);
    handleResetPdv();

    const { success, data } = await getLeituraCaixa(
      cod_loja,
      num_pdv,
      cod_operador,
    );
    if (!success) return;

    const leitura = {
      abertura: data.abertura_json,
      vendas: data.vendas_json,
      reforco: data.reforco_json,
      sangria: data.sangria_json,
    };

    const loja = {
      cod_loja: Number(cod_loja),
      cod_operador: Number(cod_operador),
      num_pdv: Number(num_pdv),
    };

    const totaisLeitura = calcularTotaisLeitura(leitura);
    const dataLeituras = { leitura, saldo_final: totaisLeitura, loja };

    const leituraCaixa = new ImprimirLeituraCaixa(dataLeituras);
    const created = await leituraCaixa.create();
    gerarCupomCaixa(created, 'open');

    handleInitialStatePdv(null);
  }, [
    handleChangeOpenModalFunction,
    handleInitialStatePdv,
    handleResetPdv,
    initialStatePdv,
    onOpenModal,
    toggleStatusPdv,
  ]);

  const handleOpenModal = useCallback(
    (modal: ModalType) => {
      handleChangeOpenModalFunction(false);
      onOpenModal(modal);
    },
    [handleChangeOpenModalFunction, onOpenModal],
  );

  const handleClick = useCallback(
    (idx: number) => {
      switch (idx) {
        case 0:
          handleOpenModal('buscarVendas');
          break;
        case 1:
          handleOpenModal('reforco');
          break;
        case 2:
          handleOpenModal('sangria');
          break;
        case 3:
          handleOpenModal('leituraDeCaixa');
          break;
        case 4:
          handleClosePdv();
          break;

        default:
          break;
      }
    },
    [handleClosePdv, handleOpenModal],
  );

  useEffect(() => {
    const handleOpenModalKeyboard = (event: KeyboardEvent) => {
      if (isOpenModalFunction) {
        switch (event.key) {
          case '0':
            Promise.resolve(handleClosePdv());
            break;
          case '1':
            handleOpenModal('buscarVendas');
            break;
          case '2':
            handleOpenModal('reforco');
            event.preventDefault();
            break;
          case '3':
            handleOpenModal('sangria');
            break;
          case '4':
            handleOpenModal('leituraDeCaixa');
            break;

          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleOpenModalKeyboard);
    return () => window.removeEventListener('keydown', handleOpenModalKeyboard);
  }, [handleClosePdv, handleOpenModal, isOpenModalFunction]);

  return (
    <Modal
      isOpen={isOpenModalFunction}
      title="Selecione a Função"
      onClose={() => handleChangeOpenModalFunction(false)}
      idModal="modal-funcao"
    >
      <ButtonContainer>
        {functionsMap.map(({ icon: Icon, label, shortcut }, idx) => (
          <Button key={label} type="button" onClick={() => handleClick(idx)}>
            <span>
              <Icon size={23} />
              {label}
            </span>
            ({shortcut})
          </Button>
        ))}
      </ButtonContainer>
    </Modal>
  );
};
