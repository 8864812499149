import styled from 'styled-components';

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  gap: 12px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: none;

  padding: 10px;
  border-radius: 5px;

  width: 60%;
  font-size: medium;
  font-weight: 500;

  span {
    display: inline-flex;
    align-items: center;
    gap: 7px;
  }
`;
