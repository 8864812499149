import { Box } from '@material-ui/core';
import { DataGrid, GridCellParams, GridColumns } from '@material-ui/data-grid';
import { nanoid } from 'nanoid';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { InputMoney } from '~/components/NovosInputs';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { aberturaPdv } from '~/pages/PdvOnline/services/abertura-pdv';
import { getSangriaFinalizadora } from '~/pages/PdvOnline/services/sangria';
import { FinalizadorasSangriaProps } from '~/pages/PdvOnline/types';
import { codTipoMov } from '~/pages/PdvOnline/utils/codTipoMov';
import { Modal } from '..';
import { ButtonContainer, ButtonOk, SangriaContent } from './styles';
import { moneyFormat } from '~/utils/functions';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { getSaldoCaixa } from '~/pages/PdvOnline/services/getSaldoCaixa';
import { formatarValorMonetario } from '~/pages/PdvOnline/utils/formatarValorMonetario';

const MySwal = withReactContent(Swal);

export const ModalSangria: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [finalizadoras, setFinalizadoras] = useState<
    FinalizadorasSangriaProps[]
  >([]);

  const {
    isModalOpen,
    onCloseModal,
    initialStatePdv,
    handleChangeOpenModalFunction,
    handleSaldoPdv,
  } = usePdvOnline();

  const {
    register,
    setValue,
    resetField,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm();

  const buttonOKref = useRef<HTMLButtonElement | null>(null);

  const modalOpen = isModalOpen.isOpen && isModalOpen.modal === 'sangria';

  const getFinalizadora = useCallback(async () => {
    if (!initialStatePdv || !modalOpen) return;

    const { cod_operador, num_pdv, cod_loja } = initialStatePdv;

    const result = await getSangriaFinalizadora(
      Number(cod_operador),
      Number(num_pdv),
      Number(cod_loja),
    );

    const finalizadorasSangria = result.map((r) => ({
      id: nanoid(),
      cod_finalizadora: r.cod_finalizadora,
      des_finalizadora: r.des_finalizadora,
      val_saldo: moneyFormat(r.val_saldo),
    }));

    setFinalizadoras(finalizadorasSangria);
  }, [initialStatePdv, modalOpen]);

  useEffect(() => {
    getFinalizadora();
  }, [getFinalizadora]);

  const columns: GridColumns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'cod_finalizadora',
      headerName: 'Codigo Finalizadora',
      hide: true,
      width: 100,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: 'des_finalizadora',
      headerName: 'Finalizadora',
      sortable: false,
      headerAlign: 'left',
      align: 'left',
      flex: 1,
    },
    {
      field: 'val_saldo',
      headerName: 'Saldo',
      sortable: false,
      headerAlign: 'right',
      align: 'right',
      width: 150,
    },
    {
      field: '',
      headerName: 'Val. Sangria',
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      width: 150,
      cellClassName: 'super-app-theme--cell',
      headerClassName: 'super-app-theme--header',
      disableColumnMenu: true,
      renderCell: (params: GridCellParams) => {
        const { row, api } = params;

        const isFirstItem = api.getRowIndex(row.id) === 0;

        return (
          <div style={{ marginBottom: '0.625rem' }}>
            <InputMoney
              placeholder="0,00"
              min={0}
              decimals={2}
              max={9999}
              maxLength={9999}
              name={`vlr_sangria_${row.cod_finalizadora}`}
              register={register}
              autoFocus={isFirstItem}
              showIcon={false}
              autoComplete="off"
              disabled={loading}
              isError={!!errors.vlr_sangria}
              onInput={(ev: ChangeEvent<HTMLInputElement>) => {
                setValue(
                  `vlr_sangria_${row.cod_finalizadora}`,
                  ev.target.value,
                );
              }}
            />
          </div>
        );
      },
    },
  ];

  const onClose = useCallback(() => {
    onCloseModal();
    setLoading(false);

    finalizadoras.forEach((input) => {
      resetField(`vlr_sangria_${input.cod_finalizadora}`);
    });

    handleChangeOpenModalFunction(true);
  }, [finalizadoras, handleChangeOpenModalFunction, onCloseModal, resetField]);

  useEffect(() => {
    const handleCloseModal = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Escape') onClose();
    };

    window.addEventListener('keydown', handleCloseModal);
    return () => window.removeEventListener('keydown', handleCloseModal);
  }, [modalOpen, onClose]);

  useEffect(() => {
    const handleCloseModal = (ev: KeyboardEvent) => {
      if (modalOpen && ev.key === 'Enter' && buttonOKref.current)
        buttonOKref.current.click();
    };

    window.addEventListener('keydown', handleCloseModal);
    return () => window.removeEventListener('keydown', handleCloseModal);
  }, [modalOpen]);

  const handleUnsuccessfulOperation = useCallback(async (message: string) => {
    const isMissingMovementType = message.includes(
      'Para lançar esse movimento é necessário ter o tipo',
    );

    if (isMissingMovementType) {
      await MySwal.fire({
        title: message,
        icon: 'warning',
        html: `
          <span>
            <p>1. Vá para a tela “Cadastro de Tipo de Movimento de Caixa“.</p>
            <p>2. Cadastre com o tipo e operação compatível à operação desejada.</p>
            <p>3. Volte aqui e tente novamente.</p>
          </span>
        `,
      });
    }
  }, []);

  const onClickOk = useCallback(async () => {
    if (!initialStatePdv) return;

    const save = finalizadoras.map((inp) => ({
      cod_finalizadora: inp.cod_finalizadora,
      val_mov: formatarValorMonetario(
        getValues(`vlr_sangria_${inp.cod_finalizadora}`) as string,
      ),
    }));

    const onlyValues = save.filter(
      (val) => val.val_mov !== '' && val.val_mov !== '0,00',
    );

    if (onlyValues.length === 0) {
      toast.warning('Pelo menos uma finalizadora deve ter seu valor informado');
      setFocus(`vlr_sangria_${save[0].cod_finalizadora}`);
      return;
    }

    setLoading(true);

    const { success, data, message } = await aberturaPdv.insereMovimento(
      initialStatePdv.num_pdv,
      initialStatePdv.cod_loja,
      {
        cod_operador: initialStatePdv.cod_operador,
        cod_tipo_mov: codTipoMov.SANGRIA,
        val_sangria: onlyValues,
      },
    );

    if (!success) {
      handleUnsuccessfulOperation(message);
      setLoading(false);
      return;
    }

    if (data) {
      onClose();
      const { saldo } = await getSaldoCaixa(
        Number(initialStatePdv.cod_operador),
        Number(initialStatePdv.num_pdv),
        Number(initialStatePdv.cod_loja),
      );

      handleSaldoPdv(moneyFormat(saldo.toString()));
      toast.success('Sangria realizada com sucesso');
    }

    setLoading(false);
  }, [
    finalizadoras,
    getValues,
    handleUnsuccessfulOperation,
    initialStatePdv,
    onClose,
    setFocus,
    handleSaldoPdv,
  ]);

  return (
    <Modal isOpen={modalOpen} title="Sangria" onClose={onClose}>
      <SangriaContent>
        <Box sx={{ height: 230, width: 650 }}>
          <DataGrid
            rows={finalizadoras}
            columns={columns}
            disableColumnFilter
            disableColumnSelector
            disableSelectionOnClick
            disableColumnMenu
            hideFooterPagination
            hideFooterRowCount
            hideFooterSelectedRowCount
            hideFooter
            localeText={{
              noRowsLabel: 'Nenhum finalizadora encontrada',
              columnMenuLabel: 'Menu',
              columnMenuFilter: 'Filtrar',
              columnMenuHideColumn: 'Esconder',
              columnMenuUnsort: 'Não ordenar',
              columnMenuSortAsc: 'Ordernar ASC',
              columnMenuSortDesc: 'Ordernar DESC',
              columnMenuShowColumns: 'Mostrar colunas',
            }}
          />
        </Box>
        <ButtonContainer>
          <ButtonOk
            disabled={loading || finalizadoras.length <= 0}
            type="button"
            onClick={onClickOk}
            ref={buttonOKref}
          >
            OK
          </ButtonOk>
        </ButtonContainer>
      </SangriaContent>
    </Modal>
  );
};
