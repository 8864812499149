import styled from 'styled-components';

export const ReforcoContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
`;

export const InputFinalizadoraContent = styled.div`
  width: 18.125rem;
`;

export const ButtonRefoco = styled.button`
  border: none;
  background-color: #019d0f;
  color: white;
  padding: 0.5rem;
  width: 6.875rem;
  margin-top: 1.875rem;
  border-radius: 0.4375rem;
  text-transform: uppercase;
  font-weight: 500;
  font-size: large;

  &:disabled {
    opacity: 60%;
  }
`;
