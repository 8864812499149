import React, { ReactNode, useEffect, useRef } from 'react';
import {
  ModalCloseButton,
  ModalContainer,
  ModalHeader,
  ModalOverlay,
  ModalTitle,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  title?: string;
  children: ReactNode;
  idModal?: string;
}

export const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  title = '',
  children,
  idModal = undefined,
}) => {
  const buttonCloseRef = useRef<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.focus();
      const elementsOutsidModal = document.querySelectorAll(
        'body > :not([aria-modal="true"])',
      );

      elementsOutsidModal.forEach((element) => {
        element.setAttribute('aria-hidden', 'true');
      });

      const handleKeyDown = (ev: KeyboardEvent) => {
        if (ev.key === 'Tab') {
          ev.preventDefault();

          if (modalRef.current) {
            const focusableElements: any = modalRef.current.querySelectorAll(
              'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input:not([disabled]), select:not([disabled])',
            );

            const activeElementIndex = Array.from(focusableElements).findIndex(
              (el) => el === document.activeElement,
            );

            if (ev.shiftKey) {
              const previousElement =
                activeElementIndex === 0
                  ? focusableElements[focusableElements.length - 1]
                  : focusableElements[activeElementIndex - 1];

              previousElement?.focus();
            } else {
              const nextElement =
                activeElementIndex === focusableElements.length - 1
                  ? focusableElements[0]
                  : focusableElements[activeElementIndex + 1];

              nextElement?.focus();
            }
          }
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => {
        window.removeEventListener('keydown', handleKeyDown);

        elementsOutsidModal.forEach((element) => {
          element.removeAttribute('aria-hidden');
        });
      };
    }
  }, [isOpen]);

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer ref={modalRef} id={idModal}>
        <ModalHeader>
          {title && <ModalTitle>{title}</ModalTitle>}
          {onClose && (
            <ModalCloseButton ref={buttonCloseRef} onClick={onClose}>
              &times;
            </ModalCloseButton>
          )}
        </ModalHeader>
        {children}
      </ModalContainer>
    </ModalOverlay>
  );
};
