import styled from 'styled-components';

export const SangriaContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 40.625rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.625rem;
`;

export const ButtonOk = styled.button`
  border: none;
  padding: 0.625rem;
  background-color: #039e13;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: large;
  border-radius: 0.4375rem;
  width: 8.125rem;

  &:disabled {
    opacity: 60%;
    cursor: not-allowed;
  }
`;
