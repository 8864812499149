import { nanoid } from 'nanoid';
import React, { useMemo } from 'react';
import { IconType } from 'react-icons';
import { FinalizaoraProps } from '~/pages/PdvOnline/types';
import { ContainerFinalizadoras } from './styles';
import { moneyFormat } from '~/utils/functions';

interface Finalizaoras extends FinalizaoraProps {
  value: number;
}

interface LeituraProps {
  icon: IconType;
  label: string;
  finalizadoras: Finalizaoras[] | null;
}

export const Leitura: React.FC<LeituraProps> = ({
  icon: Icon,
  label,
  finalizadoras,
}) => {
  const finalizadorasTotalValue = useMemo(() => {
    if (label.toUpperCase() === 'ABERTURA') return finalizadoras;

    if (!finalizadoras) return [{ des_finalizadora: 'TOTAL', value: 0 }];

    const val_total = finalizadoras.reduce((acc, f) => acc + f.value, 0);

    return [...finalizadoras, { des_finalizadora: 'TOTAL', value: val_total }];
  }, [finalizadoras, label]);

  const renderFinalizadora = (
    f: Finalizaoras | { des_finalizadora: string; value: number },
  ) => (
    <div key={nanoid()} className="finalizadoras">
      <p>{f.des_finalizadora}</p>
      <p>
        R$ {moneyFormat(f.value.toFixed(2))}
        {label.toUpperCase() === 'SANGRIA' && ' -'}
      </p>
    </div>
  );

  return (
    <ContainerFinalizadoras>
      <div className="header-finalizadoras">
        <Icon size={25} color="#9802FE" />
        <p>{label.toUpperCase()}</p>
      </div>
      <div className="body-finalizadoras">
        {finalizadorasTotalValue?.map(renderFinalizadora)}
      </div>
    </ContainerFinalizadoras>
  );
};
